<!--Navbar-->
<mat-toolbar>
	<mat-icon (click)="drawer.toggle()" class="burger app-cursor">menu</mat-icon>
	<img alt="Logo" src="assets/icon/ips.jpeg" class="logo-app"  style="margin-left: 15px;"/>
	<span class="example-spacer"></span>
	 <label class="admin-text app-m-name"> Administrador</label>
	<mat-icon [matMenuTriggerFor]="menu" class="burger app-cursor">keyboard_arrow_down</mat-icon>
  <mat-menu #menu="matMenu">
		<button mat-menu-item (click)="logout()">
			<img src="assets/icons/logout_baseline.svg" alt="" class="app-m-left">
			<span class="user-text app-m-left-15">Cerrar sesión</span>
		</button>
	</mat-menu>
	
</mat-toolbar>

<mat-drawer-container class="example-container" autosize>
	<mat-drawer #drawer class="example-sidenav" mode="side">
		<p class="user-text" routerLink="/list">Lista</p>
		<p class="user-text" routerLink="/schedule">Horario</p>
		<p class="user-text"routerLink="/home">Cargar Archivo</p>
	</mat-drawer>
</mat-drawer-container>

<div class="schedule ">
    <div class="schedule__box">
    <div class="schedule__title">Horario Médico</div>
    <div class="schedule__position">
      <button mat-fab color="primary" aria-label="" routerLink="/doctor-schedule">
        <mat-icon>person_add</mat-icon>
      </button>
    </div>
      <div class="schedule__container">
        <div class="schedule__table">
          <section>
            <header>
              <div class="col schedule__item">Nombres</div>
              <div class="col schedule__item">Apellidos</div>
              <div class="col schedule__item">Especialidad</div>
              <div class="col schedule__item">Acciones</div>
            </header>
            <div class="row" *ngFor="let doctor of doctorsList">
              <div class="col">{{doctor.first_names}}</div>
              <div class="col">{{doctor.last_names}}</div>
              <div class="col">{{doctor.category.name}}</div>
              <div class="col">
                <button  class="schedule__btnSend" (click)="getSchedule(doctor.id)"><img src="assets/icon/eye.png" alt=""></button>
                <a href="#" [routerLink]="['/doctor-schedule', doctor.id]"><button  class="schedule__btnSend"><img src="assets/icon/pencil.png" alt=""></button></a>
                <button  class="schedule__btnSend" (click)="delete(doctor.id)"><img src="assets/icon/delete.png" alt=""></button>
              </div>
            </div>
            <div class="row" *ngIf="doctorsList == ''">
              <div class="col">no hay doctores</div>
            </div>
          </section>
        </div>      
      </div>
    </div>
  </div>