<div class="login">
  <div class="login__box" *ngIf="!close">
    <img src="assets/icon/LOGO_IPS.png" class="login__img" alt="">
    <div class="temporal">
      <div class="temporal__title">
        SEÑOR USUARIO: A PARTIR DEL LUNES 2 DE OCTUBRE NO SE ASIGNARÁN CITAS MÉDICAS PARA TRÁMITES MIPRES, DEBEN PRESENTARSE PERSONALMENTE A LA IPS, CON TODA LA DOCUMENTACIÓN, CON LA FUNCIONARIA MARIANA BLANQUICET.
      </div>
    </div>
    <div class="login__content">
      <form class="login__form" [formGroup]="dniForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="">
          <input matInput placeholder="Cédula" formControlName="dni">
        </mat-form-field>

        <div class="inputResponsive">
          <button class="btn" type="submit">
            <span>Ingresar</span>
          </button>
        </div>
      </form>
      <div class="error-label" *ngIf="msg != ''">
        {{ msg }}
      </div>
    </div>
  </div>
  <div class="login__box" *ngIf="close">
    <img src="assets/icon/LOGO_IPS.png" class="login__img" alt="">
    <div class="login__done-appointment">
      El horario de atención es de 6:30am a 5:00pm
    </div>
  </div>
</div>