<body>
  <!-- Navbar Section -->
  <div class="nav">
    <input type="checkbox" id="nav-check">
    <div class="nav-header">
    </div>
    <div class="nav-btn">
      <label for="nav-check">
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
    <div class="nav-links">
      <a  routerLink="/service">Servicios</a>
      <a routerLink="/duties">Deberes y derechos</a>
      <a routerLink="/about-us">Nosotros</a>
    </div>
  </div>
  <!-- This section is only for responsive -->
  <div class="nav-title-none">
    <div class="nav--container ">
      Contáctenos<span class="nav-title-span-none">Tel: 8331464 – 8327642 Cel. 3216434722</span> 
    </div>
  </div>
  <!-- Hero Section -->
  <div class="hero">
    <div class="hero__hidden">
      <div class="nav-title-right">
        <label> Contáctenos</label>
        <label class="nav-title-span">Tel: 8331464 – 8327642 Cel. 3216434722
        </label> 
      </div>
    </div>
    <div class="hero-bg">
      <div class="hero__box">
        <div class="hero__title">
          I.P.S SALUD ANTIOQUIA
        </div>
        <p class="hero__subtitle">Seguridad, Salud y Bienestar</p>
        <p class="hero__subtitle">Ingresa en la pagina web y podras agendar tu cita con nosotros</p>
        <div class="hero__zip--box">
          <a href="https://www.saludantioquia.com/" target="_blank"class="hero--button">Click Aquí</a>
        </div>
      </div>
    </div>
  </div>
  <!-- container One -->
  <div class="container-one">
    <div class="container-one__box">
      <div class="container-one__title">¿Qué es I.P.S Salud Antioquia?</div>
      <div class="container-one__subtitle">
        Somos una empresa prestadora de servicios de salud de primer nivel de atención,
          dirigidos a EPS reconocidas fundamentada en el mejoramiento continuo con
          calidad y la atención humanizada contribuyendo al bienestar de nuestros 
          usuarios y la sostenibilidad de la empresa.
      </div>
    </div>
  </div>

  <!-- container horario -->
  <div class="container-one">
    <div class="container-one__box-info">
      <div class="container-one__title">
        <img src="assets/icon/ips.jpeg" alt=""class="container-two--top">

      </div>
      <p class="container-two__info">
        Horario toma de muestras laboratorio  de  lunes a viernes de 6:30 am a 9:00 am 
      </p>
      <p class="container-two__info">
        Toma citologías de lunes a viernes 9:00 a 11 :00 am 
      </p>
      <p class="container-two__info">
        Entrega de resultados de lunes a viernes de 4 :00 pm a 5:00 pm
      </p>
    </div>
  </div>
  <!-- container Two -->
  <div class="container-two">
    <div class="box">
      <div class="col-4 col-s-12 container-two__content">
        <div class="container-two__img"><i class="fa fa-syringe"></i></div>
        <div class="container-two__text">Solicita tus citas médicas de manera fácil y rápida.</div>
      </div>
      <div class="col-4 col-s-12 container-two__content">
        <div class="container-two__img"> <i class="fa fa-user-nurse"></i></div>
        <div class="container-two__text">Servicios y especialidades</div>
      </div>
      <div class="col-4 col-s-12 container-two__content">
        <div class="container-two__img"><i class="fa fa-user-shield"></i></div>
        <div class="container-two__text">Medidas de prevención y control</div>
      </div>
    </div>
  </div>

  <!-- container Three -->
  <div class="container-two">
    <div class="container-one__title-two">Conozca un poco sobre nosotros</div>
  </div>
  <div class="container-white">
    <div class="container-two">
      <div class="box">
        <div class="col-6 container-two__img--box">
          <img src="assets/er.jpg" alt=""class="container-two--top">
        </div>
        <div class="col-4 col-s-12 container-two__box">
          <h2 class="container-two__title">Inicios</h2>
          <p class="container-two__paragraph">
            IPS Salud Antioquia tiene sus inicios en el año 2008, 
            cuando un grupo de personas interesadas en conformar 
            un equipo de profesionales que pudieran prestar servicios
             de salud en forma integral a la población de Puerto Berrio,
              pues habían detectado la ausencia de prestadores de salud en la zona.
            Finalmente,se realiza la inauguración en febrero 15 de 2009, 
            en la actualidad la IPS cuenta una sede en el municipio de 
            Puerto Berrio para la prestación del servicio de laboratorio 
            clínico, medicina general, medicina laboral, odontología, 
            programas para el sistema de salud y seguridad en el trabajo.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-parts">
    <div class="container-two">
      <div class="box">
        <div class="col-4 col-s-12 container-two__box">
          <h2 class="container-two__title">Misión</h2>
          <p class="container-two__paragraph">
            Somos una empresa prestadora de servicios de salud de primer 
            nivel de atención, con calidad y atención humanizada contribuyendo al bienestar de nuestros usuarios.
          </p>
          <h2 class="container-two__title">Visión</h2>
          <p class="container-two__paragraph">
            “IPS SALUD ANTIOQUIA será para el 2025 la mejor 
            institución prestadora de servicios de salud del 
            Magdalena Medio por sus altos atributos de calidad, con resolución oportuna y atención segura.
          </p>
        </div>
        <div class="col-6 container-two__img--box">
          <img src="assets/doctor.png" alt="" class="container-two--top" width="250px">
        </div>
      </div>
    </div>
  </div>
  <div class="container-white">
    <div class="container-two">
      <div class="box">
        <div class="col-6 container-two__img--box">
          <img src="assets/front.jpeg" alt=""class="container-two--top">
        </div>
        <div class="col-4 col-s-12 container-two__box">
          <h2 class="container-two__title">Valores</h2>
          <p class="container-two__paragraph">
            <strong>Solidaridad:</strong> Sentimiento a través del cual las personas se 
            sienten y reconocen unidas y compartiendo las mismas obligaciones,
             intereses e ideales y conformando, además uno de los pilares 
             fundamentales sobre los que se asienta la ética moderna.
          </p>
          <p class="container-two__paragraph">
            <strong>Compromiso:</strong> Condición esencial para coordinar acciones con otros.
             Es la base para producir cualquier tipo de cambio. La clave 
             de nuestros logros radica en la capacidad que poseemos para
              comprometernos a crear algo que no existía hasta ese momento.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-parts">
    <div class="container-two">
      <div class="box">
        <div class="col-4 col-s-12 container-two__box">
          <p class="container-two__paragraph">
            <strong>Coherencia:</strong> Es la correcta conducta que debemos mantener 
            en todo momento, basada en los principios familiares,
             sociales y éticos aprendidos a lo largo de nuestras 
             vidas y que nos lleva a ser íntegros y auténticos.
          </p>
          <p class="container-two__paragraph">
           <strong>Calidad:</strong> Calidad: Forma de hacer las cosas en las que, 
            fundamentalmente, predominan la preocupación para 
            satisfacer al cliente y por mejorar, día a día, 
            procesos y resultados.
          </p>
        </div>
        <div class="col-6 container-two__img--box">
          <img src="assets/inside.png" alt="" class="container-two--top">
        </div>
      </div>
    </div>
  </div>

  <div class="container-parts-white">
    <div class="container-two">
      <div class="container-two__white">
        <div class="container-two__white--code">Asignación de citas médicas
        </div> 
        <div class="container-two__white--zip--code">Ingresa en la pagina web y podras agendar tu cita con nosotros</div> 
        <div class="hero__zip--input">
          <div class="hero__zip--box">
            <a href="https://www.saludantioquia.com/" target="_blank"class="hero--button">Click Aquí</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer -->
  <div class="footer">
    <div class="footer__info">
      <br>
      <div><i class="fa fa-map-marker-alt"></i>Ubícanos en las sedes:</div>
      <div><i class="fas fa-dot-circle"></i> Sede principal Cra 7 Nro  47 - 20 al frente del hospital de Puerto Berrio
      </div>
      <div> <i class="fas fa-dot-circle"></i> Sede nueva Calle 49 Nro 6-20 al lado del portal de los frijoles en Puerto Berrio</div>
      <div>
        <br>
        <div>Hora De Atención:</div>
        <div> <i class="fas fa-dot-circle"></i>De lunes a viernes desde las 6:30 am hasta las 6:00 pm</div>
        <div> <i class="fas fa-dot-circle"></i> Sábados de 7:00 am hasta las 12:00 pm.</div>
      </div>
      <div>
        <br>
        <div>Redes Sociales</div>
        <i class="fab fa-facebook"></i>
        <a href="https://www.facebook.com/profile.php?id=100018525815686" target="_blank" class="link_facebook">Facebook</a>
      </div>
    </div>
  </div>
</body>