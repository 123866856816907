<mat-toolbar>
	<img alt="Logo" src="assets/icon/ips.jpeg" class="logo-app-user"  style="margin-left: 15px;"/>
	<span class="example-spacer"></span>
</mat-toolbar>
<!-- <div style="margin: 2rem 2rem 0;">
  <button class="btnSmall" (click)="logout()">
    <span>Cerrar sesión</span>
  </button>
</div> -->
<div class="appointment ">
  <div class="appointment__box">
    <div *ngIf="client['session'] == null || client['dni'] == rut" class="appointment__container">
      <mat-calendar [selected]="selectedDate" (selectedChange)="onSelect($event)" [dateFilter]="filterDates" class="appointment__calendar" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
      <div class="appointment__table">
        <mat-tab-group>
          <mat-tab label="Horario disponible">
            <div>
              <div *ngIf="schedules.length > 0">
                <div class="appointment__content" *ngFor="let schedule of schedules;">
                  <div class="appointment__name">
                    <label class="appointment__nameDoc">{{ schedule.first_names }} {{ schedule.last_names}}</label>
                    <label class="appointment__time">{{ schedule.category.name }}</label>
                    <label class="appointment__time" *ngFor="let hours of schedule.hours; let i = index;">
                      {{ hours }}
                      <button  mat-raised-button color="primary" (click)="toggleSchedule(i, schedule.id)" class="appointment__nameBtn">Ver horario</button>
                      <div *ngIf="isSchedules == i && isDoctor == schedule.id" class="appointment__date">
                        <div *ngFor="let interval of schedule.schedules[i].intervals" class="appointment__date-time">
                          {{interval[1] }} <button  mat-raised-button color="primary" (click)="session(interval[0], schedule, hours)">Agendar</button>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="schedules.length == 0" class="appointment__label">
                No hay horarios disponible para esta fecha
              </div>
              <div *ngIf="schedulingIsBlocked" class="appointment__label">
                No es posible agendar otra cita.
                <div *ngIf="blocker.appointment">
                  Ya tienes agendada esta cita:
                  <ul>
                    <li>Fecha: {{blocker.appointment.date}}</li>
                    <li>Hora: {{blocker.appointment.time}}</li>
                    <li *ngIf="blocker.doctor">
                      {{blocker.doctor.first_names}} {{blocker.doctor.last_names}}
                    </li>
                  </ul>
                  Podrás agendar una nueva cita 7 días después de esta. 
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Resultados">
            <div>
              <div>
                <div *ngIf="results.length > 0">
                  <div class="appointment__content" *ngFor="let result of results">
                    <div class="appointment__name">
                      <label style="font-weight:bold;">Examen: {{ result.filename }}</label>
                      <label style="font-weight:bold;">fecha: {{ result.created_at }}</label>
                      <a href="{{url}}/storage/{{result.url }}" target="_blank"><button  mat-raised-button color="primary" style="margin-top: 20px;">Descargar</button></a>
                    </div>
                  </div>
                </div>
                <div *ngIf="results.length == 0" class="appointment__label">
                  No hay Resultados cargados
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>      
    </div>
    <div *ngIf="client['session'] != null && client['dni'] != rut" class="appointment__done-appointment">
      Ya tienes una cita apartada, con Dr/a {{ doctor }} el dia {{ dateStart }}, horario: {{ timeStart }}
    </div>
  </div>
</div>