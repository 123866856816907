<div *ngIf="data.type != 'Error'">
    <div class="modal__title">CITA AGENDADA CON ÉXITO</div>
    <div class="modal__img">
        <img src="../../assets/icon/blue-check-mark.svg.med.png" alt="">
    </div>
    <mat-dialog-content class="mat-typography">
        <div class="modal__name">
            <label class="modal__doctor">{{ data.doctor }}</label>
            <label class="modal__time">{{ data.time  }}</label>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cambiar</button>
    <button  [mat-dialog-close]="true" cdkFocusInitial mat-raised-button color="primary" (click)="home()">Entendido</button>
    </mat-dialog-actions>
</div>
<div *ngIf="data.type == 'Error'">
    <div class="modal__title">Error</div>
    <mat-dialog-content class="mat-typography">
        {{ data.msg }}
    </mat-dialog-content>
    <button mat-button mat-dialog-close>Cerrar</button>
    <button  [mat-dialog-close]="true" cdkFocusInitial mat-raised-button color="primary" (click)="home()">Entendido</button>
</div>
