<!--Navbar-->
<mat-toolbar>
	<mat-icon (click)="drawer.toggle()" class="burger app-cursor">menu</mat-icon>
	<img alt="Logo" src="assets/icon/ips.jpeg" class="logo-app"  style="margin-left: 15px;"/>
	<span class="example-spacer"></span>
	 <label class="admin-text app-m-name"> Administrador</label>
	<mat-icon [matMenuTriggerFor]="menu" class=" app-cursor">keyboard_arrow_down</mat-icon>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <img src="assets/icons/logout_baseline.svg" alt="" class="app-m-left">
      <span class="user-text app-m-left-15">Cerrar sesión</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-drawer-container class="example-container" autosize>
	<mat-drawer #drawer class="example-sidenav" mode="side">
		<p class="user-text" routerLink="/list">Lista</p>
		<p class="user-text" routerLink="/schedule">Horario</p>
		<p class="user-text"routerLink="/home">Cargar Archivo</p>
    <p class="user-text"routerLink="/create/user/doctor">Crear usuario doctor</p>
	</mat-drawer>
</mat-drawer-container>

<div class="list ">
  <div class="list__box">
    <div class="list__item list__text">
      Citas asignadas
      <a href="{{url}}/api/v1/sessions/exports" download>
        <button mat-stroked-button color="primary" class="btnSmall export">Descargar</button>
      </a>
      
    </div>
    <div class="list__container">
      <div class="list__table">
        <section>
          <header>
            <div class="col list__item">Fecha</div>
            <div class="col list__item">Doctor</div>
            <div class="col list__item">Horario</div>
            <div class="col list__item">Cédula</div>
            <div class="col list__item">Nombres y apellidos</div>
            <div class="col list__item"></div>
          </header>
          <div class="row" *ngFor="let session of sessionsList">
            <div class="col">{{ session.date }}</div>
            <div class="col"><div *ngIf="session.doctor != null">{{ session.doctor.first_names }} {{ session.doctor.last_names }}</div></div>
            <div class="col">{{ session.time_start }}</div>
            <div class="col">{{session.client.dni}}</div>
            <div class="col">
              {{session.client.first_names}} {{session.client.last_names_1}} {{session.client.last_names_2}}
            </div>
            <div class="col">
              <button class="list__btnSend"(click)="cancel(session.id)"><img src="assets/icon/delete.png" alt=""></button>
            </div>
          </div>
          <div class="row" *ngIf="sessionsList == ''">
            <div class="col">no hay citas</div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>