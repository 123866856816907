<body>
  <!-- Navbar Section -->
  <div class="nav">
    <input type="checkbox" id="nav-check">
    <div class="nav-header">
    </div>
    <div class="nav-btn">
      <label for="nav-check">
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
    <div class="nav-links">
        <a routerLink="/web-page">Inicio</a>
    </div>
  </div>
  <div class="about-us__container">
    <div class="about-us__img"></div>
    <div class="about-us__text-title">Servicios</div>
  </div>
  <div class="about-us__container-one">
    <div class="about-us__title">Servicios</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <ul>
            <li>Ecografías</li>
            <li>Enfermería</li>
            <li>Farmacia</li>
            <li>Fisioterapia</li>
            <li>Fonoaudiología</li>
            <li>Ginecobstetricia</li>
            <li>Laboratorio</li>
            <li>Medicina Laboral</li>
            <li>Medicina general</li>
            <li>Nutrición</li>
            <li>Odontología</li>
            <li>Psicología</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Footer -->
  <!-- Footer -->
  <div class="footer">
    <div class="footer__info">
      <br>
      <div><i class="fa fa-map-marker-alt"></i>Ubícanos en las sedes:</div>
      <div><i class="fas fa-dot-circle"></i> Sede principal Cra 7 Nro  47 - 20 al frente del hospital de Puerto Berrio
      </div>
      <div> <i class="fas fa-dot-circle"></i> Sede nueva Calle 49 Nro 6-20 al lado del portal de los frijoles en Puerto Berrio</div>
      <div>
        <br>
        <div>Hora De Atención:</div>
        <div> <i class="fas fa-dot-circle"></i>De lunes a viernes desde las 6:30 am hasta las 6:00 pm</div>
        <div> <i class="fas fa-dot-circle"></i> Sábados de 7:00 am hasta las 12:00 pm.</div>
      </div>
      <div>
        <br>
        <div>Redes Sociales</div>
        <i class="fab fa-facebook"></i>
        <a href="https://www.facebook.com/profile.php?id=100018525815686" target="_blank" class="link_facebook">Facebook</a>
      </div>
    </div>
  </div>
</body>