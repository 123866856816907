<body>
  <!-- Navbar Section -->
  <div class="nav">
    <input type="checkbox" id="nav-check">
    <div class="nav-header">
    </div>
    <div class="nav-btn">
      <label for="nav-check">
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
    <div class="nav-links">
    <a routerLink="/web-page">Inicio</a>
    </div>
  </div>
  <div class="about-us__container">
    <div class="about-us__img"></div>
    <div class="about-us__text-title">Nuestros colaboradores</div>
  </div>
  <div class="about-us__container-one">
    <div class="about-us__title">GERENCIA Y REPRESENTANTE LEGAL</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <div class="about-us--box-two">
          <img src="assets/personas/image2.png" alt="personl" class="about-us--image" >
          <label class="about-us--label">LEONOR MARIANA CARDONA</label>
        </div>
        <img src="assets/personas/image1.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image3.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">MÉDICOS</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image4.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image5.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image6.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image9.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image13.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">ENFERMERA JEFE</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image11.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">AUXILIARES  DE ENFERMERIA</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image7.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image15.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">AUXILIAR DE LABORATORIO</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image16.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">NUTRICIÓN</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image8.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">FISIOTERAPEUTA</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image10.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">PSICÓLOGO</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image12.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">BACTERIOLOGA</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image14.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">ODONTOLOGAS</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <div class="about-us--box-two">
          <img src="assets/personas/image2.png" alt="personl" class="about-us--image" >
          <label class="about-us--label">LEONOR MARIANA CARDONA</label>
        </div>
        <div class="about-us--box-two">
          <img src="assets/personas/image18.png" alt="personl" class="about-us--image" >
          <label class="about-us--label">MERLY MELANO</label>
        </div>
      </div>
    </div>
    <div class="about-us__title">AUXILIAR DE ODONTOLOGIA </div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image17.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">ADMINISTRACION GENERAL</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image21.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image20.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image23.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image22.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image24.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image25.png" alt="personl" class="about-us--image">
        <div class="about-us--box-two">
          <img src="assets/personas/image26.png" alt="personl" class="about-us--image" >
          <label class="about-us--label">LEIDY SANCHEZ</label>
        </div>
        <div class="about-us--box-two">
          <img src="assets/personas/image27.png" alt="personl" class="about-us--image" >
          <label class="about-us--label">GLORIA LOPEZ</label>
        </div>
      </div>
    </div>
    <div class="about-us__title">SERVICIOS GENERALES</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <img src="assets/personas/image29.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image28.png" alt="personl" class="about-us--image">
        <img src="assets/personas/image30.png" alt="personl" class="about-us--image">
      </div>
    </div>
    <div class="about-us__title">REGENTE DE FARMACIA</div>
    <div class="about-us__text">
      <div class="about-us--box">
        <div class="about-us--box-two">
          <img src="assets/personas/image32.png" alt="personl" class="about-us--image" >
          <label class="about-us--label">NATALIA CATAÑO</label>
        </div>
        <img src="assets/personas/image31.png" alt="personl" class="about-us--image">
      </div>
    </div>
  </div>
  <!-- Footer -->
  <!-- Footer -->
  <div class="footer">
    <div class="footer__info">
      <br>
      <div><i class="fa fa-map-marker-alt"></i>Ubícanos en las sedes:</div>
      <div><i class="fas fa-dot-circle"></i> Sede principal Cra 7 Nro  47 - 20 al frente del hospital de Puerto Berrio
      </div>
      <div> <i class="fas fa-dot-circle"></i> Sede nueva Calle 49 Nro 6-20 al lado del portal de los frijoles en Puerto Berrio</div>
      <div>
        <br>
        <div>Hora De Atención:</div>
        <div> <i class="fas fa-dot-circle"></i>De lunes a viernes desde las 6:30 am hasta las 6:00 pm</div>
        <div> <i class="fas fa-dot-circle"></i> Sábados de 7:00 am hasta las 12:00 pm.</div>
      </div>
      <div>
        <br>
        <div>Redes Sociales</div>
        <i class="fab fa-facebook"></i>
        <a href="https://www.facebook.com/profile.php?id=100018525815686" target="_blank" class="link_facebook">Facebook</a>
      </div>
    </div>
  </div>
</body>