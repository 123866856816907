<mat-toolbar>
	<mat-icon (click)="drawer.toggle()" class="burger app-cursor">menu</mat-icon>
	<img alt="Logo" src="assets/icon/ips.jpeg" class="logo-app"  style="margin-left: 15px;"/>
	<span class="example-spacer"></span>
	<label class="admin-text app-m-name"> Administrador</label>
	<mat-icon [matMenuTriggerFor]="menu" class="burger app-cursor">keyboard_arrow_down</mat-icon>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <img src="assets/icons/logout_baseline.svg" alt="" class="app-m-left">
      <span class="user-text app-m-left-15">Cerrar sesión</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-drawer-container class="example-container" autosize>
	<mat-drawer #drawer class="example-sidenav" mode="side">
		<p class="user-text" routerLink="/list">Lista</p>
		<p class="user-text" routerLink="/schedule">Horario</p>
		<p class="user-text"routerLink="/home">Cargar Archivo</p>
    <p class="user-text"routerLink="/create/user/doctor">Crear usuario doctor</p>
	</mat-drawer>
</mat-drawer-container>

<div class="admin">
    <div class="list ">
      <div class="list__box">
        <div class="list__container">
          <div class="list__table">
            <section>
              <header>
                <div class="col list__item">Email</div>
                <div class="col list__item"></div>
              </header>
              <div class="row" *ngFor="let user of userList">
                <div class="col">{{ user.email }}</div>
                <div class="col">
                  <button class="list__btnSend" (click)="userDelete(user.id)"><img src="assets/icon/delete.png" alt=""></button>
                </div>
              </div>
            </section>
          </div>      
        </div>
      </div>
    </div>
  <div class="admin__box">
    <div>
      <img src="assets/icon/LOGO_IPS.png" class="admin__img" alt="">
      <form class="admin__form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="admin__input">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
        <mat-form-field class="admin__input">
          <mat-label>Contraseña</mat-label>
          <input type="password" matInput placeholder="Contraseña" formControlName="password">
        </mat-form-field>
        <div>
          <button class="btn" type="submit">
            <span>Crear usuario</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
